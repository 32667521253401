import Counter from "../helpers/Counter";

const Chiffre = ({chiffres}) => {

    const stringFromInteger = (x) => {
        var letr =  x.match(/[^\w\s]/g);
        return letr;
    }

    const toInteger = (chiffre) => {
        const intChiffre = parseInt(chiffre.replace(/\s/g, ''));

        return intChiffre;
    }

    return ( 
        <div className="hp-chiffre" >
            <div className="l-container" >
                <div className="intro text-center" >
                    <h2 className="title" >{chiffres.title}</h2>
                    <h3 className="white-subtitle" >{chiffres.subtitle}</h3>
                </div>
                <div className="chiffre-items flex-space" >
                    { chiffres.items.map( (item) => (
                        <div className={ item.dont ? "chiffre-item dont" : "chiffre-item" } key={item.id} >
                            { item.dont &&
                            <div className="dont-content" >
                                <p>Dont</p>
                            </div> }
                            <div className="content" >
                                <div className="img-container" >
                                    <img src={item.icone} />
                                </div>
                                <p className="chiffre" >
                                    { stringFromInteger(item.chiffre) }
                                    <Counter chiffre={toInteger(item.chiffre)} />
                                </p>
                                <p className="chiffre-item-title" >{item.title}</p>
                            </div>
                        </div>
                    ) ) }
                </div>
            </div>
        </div>
     );
}
 
export default Chiffre;