import useFetch from "../hooks/useFetch";
import CurrentLang from "../hooks/CurrentLanguageContext";
import { useContext } from "react";

const Translation = () => {
    const {currentLanguage} = useContext(CurrentLang);
    const {data: translations, isPending, error} = useFetch(`/${currentLanguage}/api/ipse-translation`, currentLanguage);
    return ( translations );
}
 
export default Translation;