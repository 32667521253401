import tobottom from '../../assets/images/tobottom.png'
import Slider from "react-slick";
import Translation from '../../config/Translation';

const SliderHp = ({slides}) => {
    const slidesLength = slides.items.length;
    const translations = Translation();

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return ( 
        <div className="hp-slider" >
            <div className='hp-slider-content' >
                { slidesLength > 1 && <Slider {...settings}>
                    {
                        slides.items.map( (item) => (
                            item.image ? 
                                <div className="item-image" key={item.id} >
                                    <img src={item.image} />
                                    <div className="infos" >
                                        <p className="slide-title" >{ item.title }</p>
                                        <a className="slide-link" href={item.link} target={ item.is_external ? "_blank" : "_self" } >{ translations && translations.field_hp_en_savoire_plus }</a>
                                    </div>
                                </div>
                            : 
                                <div className="item-video" key={item.id} >
                                    <video width="100%" height="100%" autoPlay muted loop>
                                        <source src={item.video} type="video/mp4" />
                                    </video>
                                    <div className="infos" >
                                        <p className="slide-title" >{ item.title }</p>
                                        <a className="slide-link" href={item.link} target={ item.is_external ? "_blank" : "_self" } >{ translations && translations.field_hp_en_savoire_plus }</a>
                                    </div>
                                </div>
                            
                        ))
                    }
                </Slider>}
                { slidesLength == 1 && (
                    slides.items[0].image ? 
                    <div className="item-image" >
                        <img src={slides.items[0].image} />
                        <div className="infos" >
                            <p className="slide-title" >{ slides.items[0].title }</p>
                            <a className="slide-link" href={slides.items[0].link} target={ slides.items[0].is_external ? "_blank" : "_self" }  >S’inscrire</a>
                        </div>
                    </div>
                    :
                    <div className="item-video" >
                        <video width="100%" height="100%" autoPlay muted loop>
                            <source src={slides.items[0].video} type="video/mp4" />
                        </video>
                        <div className="infos" >
                            <p className='slide-title' >{ slides.items[0].title }</p>
                            <a className='slide-link' href={slides.items[0].link} target={ slides.items[0].is_external ? "_blank" : "_self" } >S’inscrire</a>
                        </div>
                    </div>
                ) }
                <div className="to-bottom" >
                    <a href="#bottom" ><img src={tobottom} /></a>
                </div>
            </div>
        </div>
     );
}
 
export default SliderHp;