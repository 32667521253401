const Description = ({description}) => {
    return ( 
        <div className="nosecoles-desc" >
            <div className="container" >
                <p>{description}</p>
            </div>
        </div>
     );
}
 
export default Description;