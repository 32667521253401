import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";

const Ecoles = ({ecoles, options, setQuery}) => {
    const [titleQuery, setTitleQuery] = useState('');
    const [villeQuery, setVilleQuery] = useState('');
    const [cycleQuery, setCycleQuery] = useState('');
    const [gestionnaireQuery, setGestionnaireQuery] = useState('');

    const handleTitle = (event) => {    
        setTitleQuery('&title='+event.target.value );
    }
    
    const handleVille = (event) => {
            
            if( event.target.value == 'all' ){
                setVilleQuery('');
            }else{
                setVilleQuery('&ecole_site='+event.target.value );
            }
    }

    const handleCycle = (event) => {
            
        if( event.target.value == 'all' ){
            setCycleQuery('');
        }else{
            setCycleQuery('&ecole_cycle='+event.target.value );
        }
    }

    const handleGestionnaire = (event) => {
            
        if( event.target.value == 'all' ){
            setGestionnaireQuery('');
        }else{
            setGestionnaireQuery('&ecole_gestionnaire='+event.target.value );
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setQuery(titleQuery+villeQuery+cycleQuery+gestionnaireQuery);
    }
    
    
    return ( 
        <div className="ecoles" >
            <div className="container" >
                <div className="ecole-filters" >
                    <div className="icone" >
                        Filter
                    </div>
                    <div className="ecole-name" >
                        <input type="text" onChange={(event) => handleTitle(event)} />
                    </div>
                    <div className="ecole-ville" >
                        <select onChange={(event) => handleVille(event)} >
                            <option value="all" >All</option>
                            {
                                options.villes.map( (item) => (
                                    <option key={item.machine_name} value={item.machine_name} >{item.value}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="ecole-cycle" >
                        <select onChange={(event) => handleCycle(event)} >
                            <option value="all" >All</option>
                            {
                                options.cycles.map( (item) => (
                                    <option key={item.machine_name} value={item.machine_name} >{item.value}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="ecole-gestionnaire" >
                        <select onChange={(event) => handleGestionnaire(event)} >
                            <option value="all" >All</option>
                            {
                                options.gestionnaires.map( (item) => (
                                    <option key={item.machine_name} value={item.machine_name} >{item.value}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="submite-icone" >
                        <button onClick={handleSubmit} >Submit</button>
                    </div>
                </div>
                <div className="ecole-items" >
                    {
                        ecoles.map( (item) => (
                            <div className="ecole-item" key={item.nid}>
                                <div className="ecole-item-container" >
                                    <div className="ecole-item-img" >
                                        {item.field_ecole_image && <img src={item.field_ecole_image} alt={(item.field_ecole_image_1) ?? ''} /> }
                                    </div>
                                    <div className="ecole-item-content" >
                                        <h2 className="title" >{item.title}</h2>
                                        <p className="site" >{item.field_ecole_site}</p>
                                        <p className="cycle" >{item.field_ecole_cycle}</p>
                                        <p className="adress" >{item.field_ecole_adresse}</p>
                                    </div>
                                </div>
                            </div>
                        ) )
                    }
                </div>
            </div>
        </div>
     );
}
 
export default Ecoles;