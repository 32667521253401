import { useRef, useEffect, useState } from "react";

const Excellence = ({excellenceContent}) => {

    const divRef = useRef(null);
    const [heightWindows, setHeightWindows] = useState(0);
    const [isMobile, setIsMobile] = useState( window.outerWidth > 768 ? false : true);
    
    window.addEventListener( 'resize' , function(){
        if( window.outerWidth <= 768 ){
            setIsMobile(true);
        }
    });

    window.addEventListener( 'scroll' , function(){
        setHeightWindows(window.scrollY);
    });
    
    useEffect( () => {
        //Shape Animation
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0, // Trigger when at least 50% of the element is visible
        };

        const callback = (entries, observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                  let shape1 = document.getElementById('shape-1');
                  let shape2 = document.getElementById('shape-2');
                  shape1.querySelector('span').style.transform = 'rotate(' + heightWindows / 30+ 'deg)';
                  shape2.querySelector('span').style.transform = 'translateX(' + heightWindows /10+ 'px)';
                // Disconnect the observer to avoid multiple triggers
                observer.disconnect();
              }
            });
          };

        const observer = new IntersectionObserver(callback, options);

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        // Cleanup function
        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
        //End Shape Animation
    });

    useEffect(() => {

        if( !isMobile ){
            const handleScroll = () => {
                if( window.scrollY > 400 ){
                    if( document.getElementsByClassName('excellence-hp').length > 0 ){
                        var headerElement = document.getElementsByClassName('excellence-hp');
                        headerElement[0].classList.add('fadeInUpSmallSlow');
                    }
                }
            };
            window.addEventListener('scroll', handleScroll);
        }

      }, [isMobile]);
    return ( 
        <div className="excellence-hp" id="bottom" ref={divRef} >
            <span className="shape-1" id="shape-1" ><span></span></span>
            <span className="shape-2" id="shape-2" ><span></span></span>
            <div className="container" >
                <div className="excellent-hp-content" >
                    <h2 className="title" >{excellenceContent.title}</h2>
                    <h3 className="subtitle" >{excellenceContent.subtitle}</h3>
                    <p className="desc">{excellenceContent.description}</p>
                    <a className="white-btn" href={excellenceContent.link.url} target={excellenceContent.link.external ? "_blank" : "_self"} >
                        En savoir plus
                    </a>
                </div>

                <div className="excellent-hp-image" >
                    { excellenceContent.image.url &&
                        <img src={excellenceContent.image.url} alt={excellenceContent.image.alt} />
                    }
                </div>
            </div>
        </div>
     );
}
 
export default Excellence;