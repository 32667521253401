import { useEffect, useState } from "react";

const useFetchWithQuery = (url, depencies, query) => {
    const [isPending, setIsPending] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect( () => {
        fetch(url+'?'+query)
        .then(res => {
            if( !res.ok ){
                //throw Error('Could not fetch the data from that ressource');
            }
            return res.json();
        })
        .then( data => {
            console.log(data);
            setData(data);
            setIsPending(false);
            setError(null);
        })
        .catch( err => {
            setIsPending(false);
            setError( err.message );
        });
    }, [depencies, query]);

    return {data, isPending, error};
}
 
export default useFetchWithQuery;