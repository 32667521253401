import { useEffect, useRef, useState } from "react";

const Vision = ( {content} ) => {

    const firstItem = content.items.find( (item, key) => key === 0 );
    const restItems = content.items.filter( (item, key) => key !== 0 );
    const subtitle = content.subtitle.split(',');
    const [vsionHeight, setVsionHeight] = useState(0);
    const visionDiv = useRef(null);

    // distance between shape-4 & shape-5 and the top
    let shape = document.getElementById('shape-4');
    let distanceToTop = 0;
    if( shape ){
        shape = shape.getBoundingClientRect();
        distanceToTop = shape.top;
    }
    

    window.addEventListener( 'scroll' , function(){
        setVsionHeight(window.scrollY);
    });

    // Animation
    useEffect( () => {
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0, // Trigger when at least 50% of the element is visible
        };

        const callback = (entries, observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                  let shape4 = document.getElementById('shape-4');
                  let shape5 = document.getElementById('shape-5');
                  
                  shape4.querySelector('span').style.transform = 'translate(' + distanceToTop /6+ 'px, ' + - distanceToTop /5+ 'px)';
                  shape5.querySelector('span').style.transform = 'translateY(' + distanceToTop /5+ 'px)';
                // Disconnect the observer to avoid multiple triggers
                observer.disconnect();
              }
            });
          };

        const observer = new IntersectionObserver(callback, options);

        if (visionDiv.current) {
            observer.observe(visionDiv.current);
        }

        // Cleanup function
        return () => {
            if (visionDiv.current) {
                observer.unobserve(visionDiv.current);
            }
        };
    }, [vsionHeight]);

    // End Animation

    return ( 
        <div className="vision" ref={visionDiv} >
            <span className="shape-4" id="shape-4" >
                <span></span>
            </span>
            <span className="shape-5" id="shape-5" >
                <span></span>
            </span>
            <div className="container flex-space" >
                { restItems.length > 0 && (
                    <>
                    <div className="left-part" >
                        <div className="intro" >
                            <h2 className="title" >{content.title}</h2>
                            <h3 className="subtitle" >{ subtitle[0] } <br/> { subtitle[1] } </h3>
                            <p className="description" >{content.description}</p>
                        </div>
                        <div className="first-vision" >
                            <div className="vision-item hover-scale">
                                { firstItem.image && <img src={firstItem.image} /> }
                                <a href={firstItem.page_interne ? firstItem.page_interne : firstItem.page_externe} target={ firstItem.page_externe ? '_blank' : '_self' } >
                                    <h4>{firstItem.title}</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="right-part" >
                        <div className="vision-items" >
                        {   restItems && 
                            restItems.map( (item) => (
                                <div className="vision-item hover-scale" key={item.id}>
                                    { item.image && <img src={item.image} /> }
                                    <a href={item.page_interne ? item.page_interne : item.page_externe} target={ item.page_externe ? '_blank' : '_self' } >
                                        <h4>{item.title}</h4>
                                    </a>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    </>
                ) }
            </div>
        </div>
     );
}
 
export default Vision;