import useFetch from "../../hooks/useFetch";
import Config from "../../config/Config";
import CurrentLang from "../../hooks/CurrentLanguageContext";
import { useContext, useEffect, useRef, useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Actualites = () => {

    const config = Config();
    const {currentLanguage} = useContext(CurrentLang);
    const { data: actualites, isPending, error } = useFetch(config.baseUrl+`/${currentLanguage}/api/actualite-hp`, currentLanguage);
    
    const [heightactualite, setHeightactualite] = useState(0);
    const actualiteRef = useRef(null);

    window.addEventListener( 'scroll' , function(){
        setHeightactualite(window.scrollY);
    });

    // animation 
    useEffect( () => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };
        const callback = (entries, observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                  let shape3 = document.getElementById('shape-3');
                  shape3.querySelector('span').style.transform = 'rotate(' + heightactualite/30+ 'deg)';
                // Disconnect the observer to avoid multiple triggers
                observer.disconnect();
              }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        if (actualiteRef.current) {
            observer.observe(actualiteRef.current);
        }

        // Cleanup function
        return () => {
            if (actualiteRef.current) {
                observer.unobserve(actualiteRef.current);
            }
        };
    },[heightactualite]);
    //End Animation
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                }
            }
        ]
    };
    

    return ( 
        <div className="actualites-hp" id="actualite" ref={actualiteRef} >
            <span className="shape-3" id="shape-3" >
                <span></span>
            </span>
            <div className="container" >
                <div className="intro" >
                    <h2 className="title">Nos dernières news</h2>
                    <h3 className="subtitle" >Actualités</h3>
                </div>
                <div className="actualite-items grid-items">
                <Slider {...settings}>
                    { actualites && actualites.map( (item) => (
                        <div className="grid-item" key={item.nid}>
                            <img src={config.baseUrl+item.field_actualite_miniature} alt={item.field_actualite_miniature_1} />
                            <div className="infos" >
                                <p className="item-date" >
                                    Le { currentLanguage === 'en' ? new Date(1709493931 * 1000).toLocaleDateString('en') : new Date(1709493931 * 1000).toLocaleDateString('fr') }
                                </p>
                                <h4 className="item-title" >{item.titre}</h4>
                                <p className="item-desc" >{item.field_actualite_resume}</p>
                            </div>
                        </div>
                    ) ) }
                    </Slider>
                </div>
                <a href="#" className="red-btn" >Toutes les actus</a>
            </div>
        </div>
     );
}
 
export default Actualites;