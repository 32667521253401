import i18n from "i18next";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";
import Config from "../config/Config";
import cookie from "js-cookie";
import CurrentLang from "../hooks/CurrentLanguageContext";
import { useContext, useState, useEffect } from "react";
import searchIcon from '../assets/images/search-icon.svg'
import mailIcon from '../assets/images/mail-icon.svg'

const Header = () => {
    const config = Config();
    const {currentLanguage, setCurrentLanguage} = useContext(CurrentLang);
    const { data: menuItems, isPending, error } = useFetch(`/${currentLanguage}/api/ipse-menu/main`, currentLanguage);
    // Mobile menu
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuOpenHandle = () => {
        setIsMenuOpen(!isMenuOpen);
    }
    // get window size
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    
    

    const languages = [
        {
            code: 'en',
            name: 'En',
        },
        {
            code: 'fr',
            name: 'Fr'
        }
    ];
    const selecetedLang = languages.find ((item) =>  item.code === currentLanguage );

    const changeLang = ( code) => {
        cookie.set('i18next', code);
        //setCurrentLanguage(cookie.get('i18next'));
        setCurrentLanguage('fr');

        var allLangs = document.getElementsByClassName('allLangs');
        allLangs[0].classList.remove('animate__fadeInDown');
        allLangs[0].classList.remove('animate__animated');
        allLangs[0].classList.remove('show');
    }

    const showLangs = () => {
        var allLangs = document.getElementsByClassName('allLangs');
        allLangs[0].classList.toggle('animate__fadeInDown');
        allLangs[0].classList.toggle('animate__animated');
        allLangs[0].classList.toggle('show');
    }

    useEffect(() => {
        const handleScroll = () => {
            var headerElement = document.getElementsByClassName('header');
            if( window.scrollY > 300 ){
            headerElement[0].classList.add('fixed-header');
          }else{
            headerElement[0].classList.remove('fixed-header');
          }
        };
        window.addEventListener('scroll', handleScroll);
      }, []);

    return ( 
        <div className="header">
            <div className="l-container flex-space" >
                <div className="logo" >
                    <Link to="/" > { config.data && <img src={config.data.logo.url} alt={config.data.logo.alt} /> }</Link>
                </div>
                <div className="menu-principal" >
                    { menuItems && windowSize > 1024 && <Menu items={menuItems}  ></Menu> }
                    { menuItems && windowSize <= 1024 &&config.data &&  <MenuMobile items={menuItems} logo={config.data.logo.url} isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}></MenuMobile> }
                    <div className="icones" >
                        <a href="mailto:" className="mail"><img src={mailIcon} /></a>
                        <div className="switch-languages" >
                            <ul>
                                <div className="selected" onClick={showLangs}>{selecetedLang.name}</div>
                                <div className="allLangs" >
                                    {
                                        languages.map(({code, name}) => (
                                            <li key={code} onClick={ () => changeLang(code) } >
                                                {name}
                                            </li>
                                        ))
                                    }
                                </div>
                            </ul>
                        </div>
                        <a className="search" ><img src={searchIcon} /></a>
                        <div className="menu-icone" onClick={menuOpenHandle} >
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Header;