import useFetch from "../hooks/useFetch";
import CurrentLang from "../hooks/CurrentLanguageContext";
import { useContext, useEffect } from "react";
import Config from "../config/Config";
import Actualites from "../components/hp/Actualites";
import Vision from "../components/hp/Vision";
import Temoignage from "../components/hp/Temoignage";
import SliderHp from "../components/hp/SliderHp";
import Chiffre from "../components/hp/Chiffre";
import Excellence from "../components/hp/Excellence";

const Home = () => {

    const config = Config();
    const {currentLanguage} = useContext(CurrentLang);
    const {data, isPending, error} = useFetch(config.baseUrl+`/${currentLanguage}/api/ipse-homepage`, currentLanguage);

    useEffect( () => {
        if( data ){
            document.title = data.title + ' | IPSE';
        }
    }, [data]);

    return ( 
        <div className="Homepage" >
            { data && <SliderHp slides={data.slider} ></SliderHp> }
            { data && <Excellence excellenceContent={data.excellence} ></Excellence> }
            <Actualites></Actualites>
            { data && <Chiffre chiffres={data.chiffre} ></Chiffre> }
            { data && <Vision content={data.vision} ></Vision> }
            <Temoignage></Temoignage>
        </div>
     );
}
 
export default Home;