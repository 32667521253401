import Config from "../../config/Config";
import CurrentLang from "../../hooks/CurrentLanguageContext";
import { useContext } from "react";
import useFetch from "../../hooks/useFetch";
import play from '../../assets/images/play.png'

import {useState} from 'react'
import ModalVideo from 'react-modal-video'

const Temoignage = () => {

    const config = Config();
    const {currentLanguage} = useContext(CurrentLang);
    const { data: temoignages, isPending, error } = useFetch(config.baseUrl+`/${currentLanguage}/api/temoignages-hp`, currentLanguage);
    const [isOpen, setOpen] = useState(false)
    const [videoUrl, setvideoUrl] = useState('')

    // Decode HTML symbols
    function decodeHTMLEntities(text) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    const videoHandle = (url) => {
        setOpen(true);
        setvideoUrl(url);
    }
    return ( 
        <div className='hp-temoignage' >
            <div className="container" >
                <div className="intro" >
                    <h2 className="title" >Nous vous donnons la parole</h2>
                    <h3 className="subtitle" >Témoignages</h3>
                </div>
                <div className="red-bar" >
                    <div className="temoignage-items flex-space grid-items" >
                    { temoignages && temoignages.map((item) => (
                        <div className="temoignage-item grid-item hover-scale" key={item.nid} >
                            <div className="popup" >
                                <ModalVideo channel='custom' autoplay isOpen={isOpen} url={videoUrl} onClose={() => setOpen(false)} />
                            </div>
                            <img src={config.baseUrl+item.field_temoignage_miniature} />
                            <div className="temoignage-play" onClick={ () => videoHandle(config.baseUrl+item.field_temoignage_video)} >
                                <img src={play}/>
                            </div>
                            <div className="infos" >
                                <p className="item-desc" > { item.titre && <span className="item-title" > { item.titre }, </span> } { decodeHTMLEntities(item.field_temoignage_desc) } </p>
                            </div>
                        </div>
                    )) }
                </div>
                <a href="#"className="white-btn" >Tous les témoighnages</a>
                </div>
            </div>
        </div>
     );
}
 
export default Temoignage;