import { Link } from "react-router-dom";
import SlideToggle from "react-slide-toggle";
import eases from "eases";
import { useState } from "react";

const MenuMobile = ({items, logo, isOpen: isMenuOpen, setIsMenuOpen}) => {

    const removeLanguageCode = (url) => {
        return url.replace('/fr', '').replace('/en', '');
    }

    const [isOpen, seIsOpen] = useState(false);

    const handleExpanding = () => {
        seIsOpen(true);
    }
    const handleCollapsing = () => {
        seIsOpen(false);
    }

    return ( 
        <div className={"menu-mobile " + (isMenuOpen ? 'open-menu animate__animated animate__bounceInRight' : 'animate__animated animate__bounceOutRight') } >
            <div className="menu-logo" >
                <img src={logo} />
                <div className="menu-close" onClick={() => setIsMenuOpen(false)}>
                    <span></span>
                </div>
            </div>
            <ul className="menu-mobile-items" >
                {items.map((item, key) => (
                        <li key={key}  >
                              { !item.childs && <Link to={removeLanguageCode(item.url)} >{ item.title }</Link> } 
                                 { item.childs &&  
                                    <>
                                    <SlideToggle
                                        duration={1000}
                                        collapsed={true}
                                        easeCollapse={eases["quartOut"]}
                                        easeExpand={eases["quartOut"]}
                                        onExpanding={handleExpanding}
                                        onCollapsing={handleCollapsing}
                                    >
                                        {({ toggle, setCollapsibleElement }) => (
                                            <>
                                                <a onClick={toggle} >
                                                    { item.title }
                                                    <div className={ isOpen ? 'menu-arrow active' : 'menu-arrow' } >
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </a>
                                                <ul className="submenu" ref={setCollapsibleElement}>
                                                    {
                                                        item.childs.map( (subItem, key) => (
                                                            <li key={key} ><Link to={removeLanguageCode(subItem.url)}>{subItem.title}</Link></li>
                                                        ))
                                                    }
                                                </ul>
                                            </>
                                        )}
                                     </SlideToggle>
                                    </>
                                 }
                     </li>
                     ))}
            </ul>
        </div>
     );
}
export default MenuMobile;