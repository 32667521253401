import { Link } from "react-router-dom";


const Menu = ( {items} ) => {

    const removeLanguageCode = (url) => {
        return url.replace('/fr', '').replace('/en', '');
    }

    return ( 
        <ul className="menu-items" >
            {
                items.map((item, key) => (
                   <li key={key}  >
                        <Link target={ item.url.includes("http") ? "_blank" : "_self" } to={removeLanguageCode(item.url)} >{ item.title }</Link>
                            { item.childs &&  
                                <ul className="submenu" >
                                    <div className="white-bg" >
                                        <div className="submenu-items" >
                                            {
                                                item.childs.map( (subItem, key) => (
                                                    <li key={key} ><Link to={removeLanguageCode(subItem.url)}>{subItem.title}</Link></li>
                                                ))
                                            }
                                        </div>
                                        { item.image && (
                                            <div className="menu_image" >
                                                <img src={item.image} />
                                            </div>
                                        ) }
                                    </div>
                                </ul>
                            }
                </li>
                ))
            }
        </ul>
     );
}
 
export default Menu;