import cookie from "js-cookie";
import Config from "../config/Config";
import useFetch from '../hooks/useFetch';
import { useContext, useState } from "react";
import CurrentLang from "../hooks/CurrentLanguageContext";

const Scolaire = () => {
    const config = Config();
    const {currentLanguage} = useContext(CurrentLang);
    const { data: scolaireItems, isPending, error  } = useFetch(config.baseUrl+`/${currentLanguage}/api/ipse-soutien-scolaire`, currentLanguage);
    return ( 
        <div className="scolaire-wrapper" >
            { !isPending && <h2>{scolaireItems.title}</h2> }
            { !isPending &&
                    scolaireItems.data.map((item) => (
                        <div key={item.id} className="scolaire-item" >
                                <h2><a href={item.link} target="_blank">{item.name}</a></h2>
                                <div dangerouslySetInnerHTML={{__html: item.description}} ></div>{}
                                <img src={item.image} />
                            </div>
                        ))
            }
        </div>
     );
}
 
export default Scolaire;