const Banner = ({banner, bannerTitle}) => {
    return ( 
        <div className="banner" >
            <div className="banner-image" >
                 { banner.image &&  <img src={banner.image} alt={(banner.alt) ?? ''}  /> }
            </div>
            <div className="container" >
                <h1 className="title" >{ bannerTitle }</h1>
            </div>
        </div>
     );
}
 
export default Banner;