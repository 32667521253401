import useFetch from "../hooks/useFetch";
import CurrentLang from "../hooks/CurrentLanguageContext";
import { useContext } from "react";

const Config = () => {
    const baseUrl = '';
    const {currentLanguage} = useContext(CurrentLang);
    const {data: configData, isPending, error} = useFetch(`/${currentLanguage}/api/ipse-configpage`, currentLanguage);
    const config = {
        baseUrl: baseUrl,
        data: configData
    };
    return (config);
}
 
export default Config;