import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Config from "../config/Config";
import cookie from "js-cookie";

const Footer = () => {
    const currentLanguage = cookie.get('i18next');
    const config = Config();
    const {data: footerMenuItems, isPending, error} = useFetch(config.baseUrl + `/${currentLanguage}/api/ipse-menu/footer`);
    const {data: footerBottomMenuItems, isPending: isPending2, error: error2} = useFetch(config.baseUrl + `/${currentLanguage}/api/ipse-menu/footer-bottom`);
    
    let firstMenu, secondMenu, thirdMenu, fourthMenu, fifthMenu;

    const removeLanguageCode = (url) => {
        return url.replace('/fr', '').replace('/en', '');
    }

    { footerMenuItems && 
        (
            firstMenu = footerMenuItems.filter( (item, key) => key === 0 ),
            secondMenu = footerMenuItems.filter( (item, key) => key >0 && key <=2 ),
            thirdMenu = footerMenuItems.filter( (item, key) => key === 3 ),
            fourthMenu = footerMenuItems.filter( (item, key) => key >3 && key <=6 ),
            fifthMenu = footerMenuItems.filter( (item, key) => key >6 )
        )
    }

    return ( 
        <div className="footer" >
            <div className="l-container" >
                <div className="footer-top" >
                    <div className="footer-logo" >
                     { config.data && <img  src={config.data.logo.url} /> }
                    </div>
                    <div className="footer-menu" >
                        {firstMenu &&
                        <ul>
                            {
                                firstMenu.map((item, key) => (
                                    <li key={key} >
                                        <Link to={removeLanguageCode(item.url)} >{item.title}</Link>
                                        { item.childs &&
                                            (
                                                <ul>
                                                {
                                                    item.childs.map ( (child, key) => (
                                                        <li key={key} >
                                                            <Link to={removeLanguageCode(child.url)} >{child.title}</Link>
                                                        </li>
                                                    ))
                                                }
                                                </ul>
                                            )
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                        }
                        {secondMenu &&
                        <ul>
                            {
                                secondMenu.map((item, key) => (
                                    <li key={key} ><Link to={removeLanguageCode(item.url)} >{item.title}</Link></li>
                                ))
                            }
                        </ul>
                        }
                        {thirdMenu &&
                        <ul>
                            {
                                thirdMenu.map((item, key) => (
                                    <li key={key} >
                                        <Link to={removeLanguageCode(item.url)} >{item.title}</Link>
                                        { item.childs &&
                                            (
                                                <ul>
                                                {
                                                    item.childs.map ( (child, key) => (
                                                        <li key={key} >
                                                            <Link to={removeLanguageCode(child.url)} >{child.title}</Link>
                                                        </li>
                                                    ))
                                                }
                                                </ul>
                                            )
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                        }
                        {fourthMenu &&
                        <ul>
                            {
                                fourthMenu.map((item, key) => (
                                    <li key={key} ><Link to={removeLanguageCode(item.url)} >{item.title}</Link></li>
                                ))
                            }
                        </ul>
                        }
                        {fifthMenu &&
                        <ul>
                            {
                                fifthMenu.map((item, key) => (
                                    <li key={key} ><Link to={removeLanguageCode(item.url)} >{item.title}</Link></li>
                                ))
                            }
                        </ul>
                        }
                    </div>
                </div>
            </div>
            <div className="footer-bottom" >
                <ul>
                    <li>{ config.data && config.data.copyright }</li>
                    { footerBottomMenuItems && footerBottomMenuItems.map( (item, key) => (
                        <li key={key} ><a href={item.url} >{item.title}</a></li>
                    ) ) }
                    <li>Réalisation: <a href="https://www.lnet.ma/" target="_blank" >Lnet</a></li>
                </ul>
            </div>
        </div>
     );
}
 
export default Footer;