import useFetch from "../hooks/useFetch";
import CurrentLang from "../hooks/CurrentLanguageContext";
import { useContext, useEffect, useState } from "react";
import Banner from "../components/helpers/Banner";
import Description from "../components/nosEcoles/Description";
import Ecoles from "../components/nosEcoles/Ecoles";
import { useNavigate } from "react-router-dom";
import useFetchWithQuery from "../hooks/useFetchWithQuery";

const NosEcoles = () => {
    const {currentLanguage} = useContext(CurrentLang);
    const [query, setQuery] = useState('');

    const {data: content, isPending, error} = useFetch(`/${currentLanguage}/api/ipse-pages/nos-ecoles`, currentLanguage);
    const {data: ecoles, isPending: ecolesIsPending, error: ecoleError} = useFetchWithQuery(`/${currentLanguage}/api/nos-ecoles`, currentLanguage, query);
    const {data: filterOptions, isPending: filterIsPending, error: filterError} = useFetch(`/${currentLanguage}/api/ipse-ecole-filter`, currentLanguage);

    const navigate = useNavigate();
    if( content == 'not found' ){
        return navigate('/404');
    }

    return ( 
        <div className="nos-ecole" >
            { content && <Banner banner={content.banner} bannerTitle={content.title} /> }
            { content && <Description description={content.description} /> }
            { ecoles && filterOptions && <Ecoles ecoles={ecoles} options={filterOptions} setQuery={setQuery} /> }
        </div>
     );
}
 
export default NosEcoles;