import { useRef, useEffect, useState } from "react";

const Counter = ({chiffre}) => {
    
    const divRef = useRef(null);
    const [counter, setCounter] = useState(0);

    function numberWithSpace(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    }

    useEffect(() => {
        const options = {
          root: null, // Use the viewport as the root
          rootMargin: '0px', // No margin
          threshold: 0.5, // Trigger when at least 50% of the element is visible
        };
    
        const callback = (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // Update state to indicate that the div is in view
              setTimeout( () => {
                if( counter < chiffre ){
                    if( chiffre > 1000 && chiffre <= 10000 ){
                        setCounter(counter + 50);
                    }else if(chiffre > 10000){
                        setCounter(counter + 500);
                    }else{
                        setCounter(counter + 1);
                    }
                }
            }, 1);
              // Disconnect the observer to avoid multiple triggers
              observer.disconnect();
            }
          });
        };

        const observer = new IntersectionObserver(callback, options);

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        // Cleanup function
        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, [[counter]]);
    return ( 
        <span ref={divRef} >
            {numberWithSpace(counter)}
        </span>
     );
}
 
export default Counter;