import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useTranslation, initReactI18next } from "react-i18next";
import './App.scss';
import Header from './partials/Header';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from "react";
import Footer from './partials/Footer';
import Scolaire from "./pages/Scolaire";
import NosEcoles from "./pages/NosEcoles";
import CurrentLang from "./hooks/CurrentLanguageContext";
import cookie from "js-cookie";

window.userLang = navigator.language || navigator.userLanguage;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'fr',
    detection: {
      order: ['cookie', 'path', 'htmlTag', 'localStorage'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false
    }
  });
function App() {
	//const [currentLanguage, setCurrentLanguage] = useState(cookie.get('i18next') || 'fr');
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  	const CurentLangValue = {currentLanguage, setCurrentLanguage};
  return (
	<CurrentLang.Provider value={CurentLangValue}>
    <Router>
      <div className='wrapper'>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ipse-soutien-scolaire" element={<Scolaire />} />
          <Route path="/nos-ecoles" element={<NosEcoles />} />
        </Routes>
        <Footer />
      </div>
    </Router>
	</CurrentLang.Provider>
  );
}

export default App;